import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from '@atoms/Link'

import css from "@css/layouts/l-error.module.styl"

export default function Error404()
{
	// const q = useStaticQuery(graphql`
    //     query {
    //         dot : file(relativePath: { eq: "hero__img__dot.png" }) {
    //             childImageSharp { fixed( width: 2, height: 2) {
    //                     ...GatsbyImageSharpFixed
    //                 }
    //             }
    //         }
    //     }
    // `)
	return(
		<section className={css.lError}>
			<div className={css.lError__inner}>
				<h2 className={css.lError__title}>404 Not Found</h2>
				<p className={css.lError__text}>お探しのページは見つかりませんでした</p>
				<div className={css.lError__footer}>
					<TransitionLink className={css.item} to="/">トップへ戻る</TransitionLink>
				</div>
			</div>
		</section>
	)
}
